<template>
  <div v-cloak class="row">
    <div class="col-md-12">
      <CToaster v-if="toastMessage1" :autohide="3000">
        <template v-for="(toast, i) in toastMessage1">
          <CToast
            :key="i"
            :show="showToast"
            header="Assign Meter"
            position="bottom-left"
          >
            {{ toast }}
          </CToast>
        </template>
      </CToaster>
      <CAlert
        color="danger"
        :show.sync="showToast"
        closeButton
        v-for="err in serverErrors"
        :key="err"
      >
        <p>{{ err }}</p>
      </CAlert>
      <form v-on:submit.prevent="assignMeter">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>Meters</label>
              <select
                name="meter_id"
                v-model="meter_id"
                class="form-control"
                required
              >
                <option value="">Select Meter</option>
                <option
                  v-for="meter in meters"
                  :value="meter.id"
                  :key="meter.id"
                >
                  {{ meter.meter_number }} - ({{ meter.hardware_serial_no }})
                </option>
              </select>
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "AssignMeterManually",
  data() {
    return {
      showToast: false,
      toastMessage: [],
      toastMessage1: [],
      serverErrors: [],
      showDismissibleAlert: false,
      meter_id: "",
    };
  },
  props: {
    meters: Array,
    customerId: "",
  },
  methods: {
    assignMeter() {
      try {
        this.axios
          .post(`/admin/customers/${this.customerId}/assign-meter`, {
            meter_id: this.meter_id,
          })
          .then(() => {
            this.toastMessage = [];
            this.$toastr.s("Meter successfuly assigned to customer");
            this.showToast = true;
          })
          .catch((err) => {
            if (err.response.data.message) {
              this.showToast = true;
              this.serverErrors.push(err.response.data.message);
              this.$toastr.e("error", err.response.data.message, "Error!");
            }
          });
      } catch (err) {
        console.log(err);
      }
    },
  },
  watch: {
    toastMessage(val) {
      this.toastMessage1 = [];
      this.toastMessage1 = val;
      console.log("updated");
    },
  },
  mounted() {},
};
</script>