<template>
  <div>
    <CAlert>
      <ul>
        <li v-for="err in serverErrors" :key="err">{{ err }}</li>
      </ul>
    </CAlert>

    <div class="qr-scanner-container">
      <qrcode-stream       
        @decode="onDecode"
        @init="onInit"
      ></qrcode-stream>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
export default {
  name: "QrScanAndAssignMeter",
  components: {
    QrcodeStream,
  },
  data() {
    return {
      serverErrors: [],
      showDismissibleAlert: false,
      meter_id: Number,
    };
  },
  props: {
    meters: Array,
    customerId: '',
  },
  methods: {
    assignMeter(serialNo) {
      try {
        this.axios
          .post(`/admin/customers/${this.customerId}/assign-meter`, {
            hardware_serial_no: serialNo,
          })
          .then((res) => {
            new Audio( require('@/assets/sounds/notification.mp3') ).play();
            this.$toastr.s(
              "success",
              "Meter successfuly assigned to customer",
              "Meter Assigned"
            );           
          })
          .catch((err) => {
            new Audio( require('@/assets/sounds/glitch-in-the-matrix.ogg') ).play();
            this.showDismissibleAlert = true;
            this.serverErrors = [];
            if (err.response.data.message) {
              this.serverErrors.push(err.response.data.message);
              this.$toastr.e(
              "error",
             err.response.data.message,
              "Error!"
            );      
            }
          });
      } catch (err) {
        console.log(err);
      }
    },
    onDecode(result) {
      if (result) {        
        this.assignMeter(result);
      }
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.qr-scanner-container {
  max-width: 400px;
  margin: 20px 15px;
  border: 5px solid white;
  background-color: #fafafa;
  min-height: 300px;
  .camera {
    width: 25%;
    margin: 0 auto;
  }
}
</style>